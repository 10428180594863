.clients-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.clients-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: flex-start;
  background-size: cover;
  justify-content: center;
  background-image: url("/img/synergenex-social-promo4_bg-1500w.webp");
  background-position: center;
}
.clients-hero1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  min-height: auto;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.clients-container01 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-hero-heading {
  color: rgb(255, 183, 186);
}
.clients-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.clients-hero-button1 {
  display: flex;
  transition: 0.3s;
  flex-direction: row;
  text-decoration: none;
}
.clients-hero-button1:hover {
  background-color: var(--dl-color-primary-300);
}
.clients-icon {
  fill: var(--dl-color-gray-white);
  width: 1em;
  height: 1em;
  align-self: center;
  margin-right: var(--dl-space-space-halfunit);
}
.clients-text05 {
  align-self: center;
}
.clients-hero-button11 {
  display: flex;
  transition: 0.3s;
  border-color: var(--dl-color-gray-white);
  border-width: 1px;
  flex-direction: row;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.clients-hero-button11:hover {
  border-color: var(--dl-color-primary-100);
  background-color: var(--dl-color-gray-white);
}
.clients-text06 {
  color: var(--dl-color-primary-100);
  align-self: center;
}
.clients-details {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.89) 0.00%,rgba(227, 227, 227, 0.9) 100.00%),url("/img/sport-smoke-red-1500w.png");
  background-position: center;
}
.clients-details1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.clients-container02 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-text09 {
  color: var(--dl-color-primary1-synergenex80);
  text-align: left;
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.clients-details-heading {
  width: 80%;
  text-align: left;
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.clients-details-sub-heading {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  text-align: left;
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-twounits);
}
.clients-image {
  width: 300px;
  align-self: center;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.clients-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clients-features-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-secondary-grey400);
}
.clients-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/img/sport-bg-smoke-1500w.png"),linear-gradient(180deg, rgba(57, 57, 57, 0.93) 0.00%,rgba(25, 25, 25, 0.9) 100.00%);
  background-position: top right,top left;
}
.clients-container03 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-text14 {
  color: var(--dl-color-primary-500);
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.clients-iteste {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.clients-container04 {
  display: flex;
  align-items: center;
  background-size: contain;
  justify-content: flex-start;
  background-image: url("/img/sport-smoke-red-1500w.png");
  background-repeat: no-repeat;
  background-position: center;
}
.clients-link {
  display: contents;
}
.clients-image1 {
  width: 400px;
  object-fit: cover;
  text-decoration: none;
}
.clients-container05 {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.clients-container06 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.clients-link01 {
  display: contents;
}
.clients-text18 {
  color: var(--dl-color-gray-white);
  font-size: 2.5em;
  font-family: "Rubik-Black";
}
.clients-container07 {
  display: flex;
  font-size: 1.3em;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.clients-text19 {
  color: var(--dl-color-gray-white);
  font-family: "Raleway";
}
.clients-hero-button2 {
  transition: 0.3s;
  border-style: none;
  text-decoration: none;
}
.clients-hero-button2:hover {
  border-style: none;
}
.clients-itestc {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.clients-container08 {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.clients-container09 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.clients-link02 {
  display: contents;
}
.clients-text23 {
  color: var(--dl-color-gray-white);
  font-size: 2.5em;
  font-family: "Rubik-Black";
}
.clients-container10 {
  display: flex;
  font-size: 1.3em;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.clients-text24 {
  color: var(--dl-color-gray-white);
  font-family: "Raleway";
}
.clients-hero-button21 {
  transition: 0.3s;
  border-style: none;
  text-decoration: none;
}
.clients-hero-button21:hover {
  border-style: none;
}
.clients-container11 {
  display: flex;
  align-items: center;
  background-size: contain;
  justify-content: flex-start;
  background-image: url("/img/sport-smoke-red-1500w.png");
  background-repeat: no-repeat;
  background-position: center;
}
.clients-link03 {
  display: contents;
}
.clients-image2 {
  width: 400px;
  object-fit: cover;
  text-decoration: none;
}
.clients-iteste1 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.clients-container12 {
  display: flex;
  align-items: center;
  background-size: contain;
  justify-content: flex-start;
  background-image: url("/img/sport-smoke-red-1500w.png");
  background-repeat: no-repeat;
  background-position: center;
}
.clients-link04 {
  display: contents;
}
.clients-image3 {
  width: 400px;
  object-fit: cover;
  text-decoration: none;
}
.clients-container13 {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.clients-container14 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.clients-link05 {
  display: contents;
}
.clients-text28 {
  color: var(--dl-color-gray-white);
  font-size: 2.5em;
  font-family: "Rubik-Black";
}
.clients-container15 {
  display: flex;
  font-size: 1.3em;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.clients-text29 {
  color: var(--dl-color-gray-white);
  font-family: "Raleway";
}
.clients-hero-button22 {
  transition: 0.3s;
  border-style: none;
  text-decoration: none;
}
.clients-hero-button22:hover {
  border-style: none;
}
.clients-itestc1 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.clients-container16 {
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.clients-container17 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.clients-link06 {
  display: contents;
}
.clients-text31 {
  color: var(--dl-color-gray-white);
  font-size: 2.5em;
  font-family: "Rubik-Black";
}
.clients-container18 {
  display: flex;
  font-size: 1.3em;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.clients-text32 {
  color: var(--dl-color-gray-white);
  font-family: "Raleway";
}
.clients-hero-button23 {
  transition: 0.3s;
  border-style: none;
  text-decoration: none;
}
.clients-hero-button23:hover {
  border-style: none;
}
.clients-container19 {
  display: flex;
  align-items: center;
  background-size: contain;
  justify-content: flex-start;
  background-image: url("/img/sport-smoke-red-1500w.png");
  background-repeat: no-repeat;
  background-position: center;
}
.clients-link07 {
  display: contents;
}
.clients-image4 {
  width: 400px;
  object-fit: cover;
  text-decoration: none;
}
.clients-features2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/img/smoke-bg-min-1500w.png"),linear-gradient(135deg, rgb(40, 40, 40) 0.00%,rgb(0, 0, 0) 99.00%);
  background-position: center;
}
.clients-container20 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-container21 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-text35 {
  color: var(--dl-color-primary1-synergenex80);
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.clients-features-heading {
  color: rgb(255, 255, 255);
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.clients-container22 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.clients-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-100);
  border-top-left-radius: 2em;
  border-top-right-radius: 2em;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
}
.clients-icon02 {
  fill: var(--dl-color-gray-white);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.clients-container23 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-text40 {
  color: var(--dl-color-gray-white);
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.clients-text41 {
  color: var(--dl-color-secondary-grey400);
  line-height: 1.6;
}
.clients-feature-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-primary-300);
  border-top-left-radius: 2em;
  border-top-right-radius: 2em;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
}
.clients-icon04 {
  fill: var(--dl-color-gray-white);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.clients-container24 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-text44 {
  color: var(--dl-color-gray-white);
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.clients-text45 {
  color: var(--dl-color-secondary-grey400);
  line-height: 1.6;
}
.clients-feature-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-primary1-blue60);
  border-top-left-radius: 2em;
  border-top-right-radius: 2em;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
}
.clients-icon06 {
  fill: var(--dl-color-primary1-synergenex80);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.clients-container25 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-text46 {
  color: var(--dl-color-primary1-synergenex80);
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.clients-text47 {
  color: var(--dl-color-gray-800);
  line-height: 1.6;
}
.clients-feature-card3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
  border-top-left-radius: 2em;
  border-top-right-radius: 2em;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
}
.clients-icon08 {
  fill: var(--dl-color-primary1-synergenex80);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.clients-container26 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.clients-text48 {
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.clients-text49 {
  color: var(--dl-color-gray-800);
  line-height: 1.6;
}
.clients-hero2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/img/synergenex-social-promo1-square-nologo-1500h.jpg");
  background-position: center;
}
.clients-link08 {
  display: contents;
}
.clients-image5 {
  height: var(--dl-size-size-small);
  display: none;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.clients-container27 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.clients-btn-group1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.clients-link09 {
  color: var(--dl-color-gray-white);
  box-shadow: 0px 5px 30px 0px #000000;
  margin-top: var(--dl-space-space-twounits);
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 45px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}
.clients-link09:hover {
  transform: scale(1.02);
}
.clients-text50 {
  font-style: normal;
  font-weight: 700;
}
.clients-text51:hover {
  transform: scale(1.02);
}
.clients-link10 {
  color: var(--dl-color-gray-white);
  box-shadow: 0px 5px 30px 0px #000000;
  margin-top: var(--dl-space-space-twounits);
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 45px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-300);
}
.clients-link10:hover {
  transform: scale(1.02);
}
.clients-text53 {
  font-style: normal;
  font-weight: 700;
}
.clients-text54:hover {
  transform: scale(1.02);
}
@media(max-width: 1600px) {
  .clients-features-container {
    background-color: #121212;
  }
  .clients-features1 {
    margin-top: 0px;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    background-image: url("/img/sport-bg-smoke-1500w.png");
    background-repeat: no-repeat;
  }
  .clients-text19 {
    align-self: center;
    text-align: left;
  }
  .clients-hero-button2 {
    text-decoration: none;
  }
  .clients-text22 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .clients-text24 {
    align-self: center;
    text-align: left;
  }
  .clients-hero-button21 {
    text-decoration: none;
  }
  .clients-text27 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .clients-text29 {
    align-self: center;
    text-align: left;
  }
  .clients-hero-button22 {
    text-decoration: none;
  }
  .clients-text30 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .clients-text32 {
    align-self: center;
    text-align: left;
  }
  .clients-hero-button23 {
    text-decoration: none;
  }
}
@media(max-width: 1200px) {
  .clients-features-container {
    background-color: #020202;
  }
  .clients-features1 {
    background-size: cover;
    background-image: url("/img/sport-bg-smoke-1500w.png");
    background-position: top right,top left;
  }
  .clients-text22 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .clients-text27 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .clients-text30 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 991px) {
  .clients-hero1 {
    flex-direction: column;
  }
  .clients-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .clients-hero-button1 {
    text-decoration: none;
  }
  .clients-icon {
    fill: var(--dl-color-gray-white);
    align-self: center;
    margin-right: var(--dl-space-space-unit);
  }
  .clients-text09 {
    color: var(--dl-color-primary1-synergenex80);
    font-family: Raleway;
  }
  .clients-details-heading {
    line-height: 1.6;
  }
  .clients-details-sub-heading {
    color: var(--dl-color-gray-800);
    font-size: 18px;
    text-align: center;
    line-height: 1.6;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .clients-features1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    background-size: auto;
    background-repeat: no-repeat;
  }
  .clients-image1 {
    width: 350px;
  }
  .clients-image2 {
    width: 350px;
  }
  .clients-image3 {
    width: 350px;
  }
  .clients-image4 {
    width: 350px;
  }
  .clients-hero2 {
    justify-content: flex-end;
  }
  .clients-link09 {
    background-color: var(--dl-color-primary1-synergenex100);
  }
  .clients-text50 {
    font-weight: 700;
  }
  .clients-link10 {
    background-color: var(--dl-color-primary-300);
  }
}
@media(max-width: 767px) {
  .clients-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .clients-details1 {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .clients-container02 {
    align-items: center;
    justify-content: flex-start;
  }
  .clients-text09 {
    text-align: center;
  }
  .clients-details-heading {
    text-align: center;
  }
  .clients-details-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .clients-image {
    width: 350px;
  }
  .clients-features1 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    background-position: top;
  }
  .clients-iteste {
    flex-direction: column;
  }
  .clients-image1 {
    width: 300px;
  }
  .clients-itestc {
    flex-direction: column-reverse;
  }
  .clients-image2 {
    width: 300px;
  }
  .clients-iteste1 {
    flex-direction: column;
  }
  .clients-image3 {
    width: 300px;
  }
  .clients-itestc1 {
    flex-direction: column-reverse;
  }
  .clients-image4 {
    width: 300px;
  }
  .clients-features2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .clients-container20 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .clients-features-heading {
    font-family: Raleway;
    line-height: 1.6;
  }
  .clients-container22 {
    grid-template-columns: 1fr;
  }
  .clients-feature-card {
    flex-direction: row;
  }
  .clients-container23 {
    flex-direction: column;
  }
  .clients-feature-card1 {
    flex-direction: row;
  }
  .clients-container24 {
    flex-direction: column;
  }
  .clients-feature-card2 {
    flex-direction: row;
  }
  .clients-container25 {
    flex-direction: column;
  }
  .clients-feature-card3 {
    flex-direction: row;
  }
  .clients-container26 {
    flex-direction: column;
  }
  .clients-hero2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .clients-link09 {
    text-align: center;
  }
  .clients-text53 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .clients-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .clients-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .clients-btn-group {
    flex-direction: column;
  }
  .clients-image {
    width: 100%;
  }
  .clients-features-container {
    background-color: #232323;
  }
  .clients-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
    background-size: contain;
    background-position: top right;
  }
  .clients-iteste {
    flex-direction: column;
  }
  .clients-image1 {
    width: 100%;
  }
  .clients-text18 {
    font-size: 2em;
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .clients-text23 {
    font-size: 2em;
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .clients-image2 {
    width: 100%;
  }
  .clients-iteste1 {
    flex-direction: column;
  }
  .clients-image3 {
    width: 100%;
  }
  .clients-text28 {
    font-size: 2em;
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .clients-text31 {
    font-size: 2em;
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
  .clients-image4 {
    width: 100%;
  }
  .clients-features2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .clients-container20 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .clients-container23 {
    flex-direction: column;
  }
  .clients-container24 {
    flex-direction: column;
  }
  .clients-container25 {
    flex-direction: column;
  }
  .clients-container26 {
    flex-direction: column;
  }
  .clients-hero2 {
    align-self: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .clients-image5 {
    align-self: center;
  }
  .clients-container27 {
    align-items: stretch;
    justify-content: flex-end;
  }
  .clients-link09 {
    align-self: stretch;
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-primary-100);
  }
  .clients-link10 {
    align-self: stretch;
    margin-top: var(--dl-space-space-unit);
    text-align: center;
    margin-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-primary-100);
  }
  .clients-text53 {
    font-style: normal;
    font-weight: 700;
  }
}
