.partners-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.partners-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: flex-start;
  background-size: cover;
  justify-content: center;
  background-image: url("/img/synergenex-social-promo4_bg-1500w.webp");
  background-position: center;
}
.partners-hero1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  min-height: auto;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}
.partners-container01 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.partners-hero-heading {
  color: #ffb7ba;
}
.partners-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.partners-hero-button1 {
  text-decoration: none;
}
.partners-hero-button2 {
  transition: 0.3s;
  text-shadow: 0 3px 20px black;
  border-width: 0px;
  text-decoration: none;
}
.partners-hero-button2:hover {
  border-style: none;
}
.partners-details {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.partners-details1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.partners-container02 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.partners-text05 {
  color: var(--dl-color-primary1-synergenex80);
  text-align: left;
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.partners-details-heading {
  width: 80%;
  text-align: left;
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.partners-details-sub-heading {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  text-align: left;
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-twounits);
}
.partners-details-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
  margin-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-round);
  object-position: top;
}
.partners-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.partners-features-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-secondary-grey400);
}
.partners-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.partners-container03 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.partners-text13 {
  color: var(--dl-color-primary1-synergenex80);
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.partners-features-heading {
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.partners-text16 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.partners-container04 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.partners-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.partners-icon {
  fill: var(--dl-color-primary1-synergenex80);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.partners-container05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.partners-text18 {
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.partners-text19 {
  color: var(--dl-color-gray-800);
  line-height: 1.6;
}
.partners-feature-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.partners-icon2 {
  fill: var(--dl-color-primary1-synergenex80);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.partners-container06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.partners-heading {
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.partners-text22 {
  color: var(--dl-color-gray-800);
  line-height: 1.6;
}
.partners-feature-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.partners-icon4 {
  fill: var(--dl-color-primary1-synergenex80);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.partners-container07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.partners-text23 {
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.partners-text24 {
  color: var(--dl-color-gray-800);
  line-height: 1.6;
}
.partners-feature-card3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.partners-icon6 {
  fill: var(--dl-color-primary1-synergenex80);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.partners-container08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.partners-text25 {
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.partners-text26 {
  color: var(--dl-color-gray-800);
  line-height: 1.6;
}
.partners-cashback {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.partners-cashback1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.partners-container09 {
  gap: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.partners-text27 {
  color: var(--dl-color-primary1-synergenex80);
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.partners-pricing-heading {
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.partners-pricing-sub-heading {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  text-align: center;
  line-height: 1.6;
}
.partners-container10 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  justify-content: center;
}
.partners-pricing-card {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 450px;
  min-height: 200px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-primary1-blue60);
}
.partners-text32 {
  font-family: "Inter";
}
.partners-container11 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.partners-text33 {
  font-family: "Montserrat";
  font-weight: bold;
  text-transform: uppercase;
}
.partners-text34 {
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 300;
}
.partners-container12 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.partners-free-plan-price {
  color: var(--dl-color-primary1-synergenex80);
  font-size: 4rem;
  align-self: flex-start;
  font-weight: 700;
}
.partners-text35 {
  color: var(--dl-color-primary1-synergenex80);
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.partners-pricing-card1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 450px;
  min-height: 200px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-primary-300);
}
.partners-text36 {
  color: var(--dl-color-primary-700);
  font-family: "Inter";
}
.partners-container13 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.partners-text37 {
  color: var(--dl-color-primary-700);
  font-family: "Montserrat";
  font-weight: bold;
  text-transform: uppercase;
}
.partners-text38 {
  color: var(--dl-color-primary-700);
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 300;
}
.partners-container14 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.partners-free-plan-price1 {
  color: var(--dl-color-gray-white);
  font-size: 4rem;
  align-self: flex-start;
  font-weight: 700;
}
.partners-text39 {
  color: var(--dl-color-gray-white);
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.partners-pricing-card2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 450px;
  min-height: 200px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-primary-100);
}
.partners-text40 {
  color: var(--dl-color-gray-900);
  font-family: "Inter";
}
.partners-container15 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.partners-text41 {
  color: var(--dl-color-gray-900);
  font-family: "Montserrat";
  font-weight: bold;
  text-transform: uppercase;
}
.partners-text42 {
  color: var(--dl-color-gray-900);
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 300;
}
.partners-container16 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.partners-free-plan-price2 {
  color: var(--dl-color-gray-white);
  font-size: 4rem;
  align-self: flex-start;
  font-weight: 700;
}
.partners-text43 {
  color: var(--dl-color-gray-white);
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 300;
}
.partners-pricing-fine-details {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.partners-pricing-fine-details1 {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  align-self: flex-start;
  line-height: 1.6;
}
.partners-pricing-fine-details2 {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  align-self: flex-start;
  line-height: 1.6;
}
.partners-hero2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/img/synergenex-social-promo1-square-nologo-1500h.jpg");
  background-position: center;
}
.partners-link {
  display: contents;
}
.partners-image {
  height: var(--dl-size-size-small);
  display: none;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.partners-container17 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.partners-btn-group1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.partners-link1 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-twounits);
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 45px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-100);
}
.partners-link1:hover {
  transform: scale(1.02);
}
.partners-text46 {
  font-style: normal;
  font-weight: 700;
}
.partners-text47:hover {
  transform: scale(1.02);
}
.partners-link2 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-twounits);
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 45px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-300);
}
.partners-link2:hover {
  transform: scale(1.02);
}
.partners-text49 {
  font-style: normal;
  font-weight: 700;
}
.partners-text50:hover {
  transform: scale(1.02);
}
@media(max-width: 1600px) {
  .partners-cashback {
    display: none;
  }
  .partners-text41 {
    color: var(--dl-color-gray-900);
    font-family: Montserrat;
    font-weight: bold;
    text-transform: uppercase;
  }
  .partners-pricing-fine-details2 {
    color: var(--dl-color-gray-800);
  }
}
@media(max-width: 1200px) {
  .partners-heading {
    font-family: Raleway;
    font-weight: 600;
    line-height: 28px;
  }
  .partners-text41 {
    color: var(--dl-color-gray-900);
  }
}
@media(max-width: 991px) {
  .partners-hero1 {
    flex-direction: column;
  }
  .partners-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .partners-details1 {
    flex-direction: column;
  }
  .partners-text05 {
    align-self: center;
    text-align: center;
  }
  .partners-details-heading {
    align-self: center;
    text-align: center;
  }
  .partners-details-sub-heading {
    color: var(--dl-color-gray-800);
    font-size: 18px;
    text-align: center;
    line-height: 1.6;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .partners-heading {
    line-height: 28px;
  }
  .partners-text22 {
    color: var(--dl-color-gray-800);
    line-height: 1.6;
  }
  .partners-pricing-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .partners-container10 {
    align-items: center;
    flex-direction: column;
  }
  .partners-pricing-card {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
  }
  .partners-pricing-card1 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
  }
  .partners-pricing-card2 {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
  }
  .partners-pricing-fine-details1 {
    text-align: left;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .partners-pricing-fine-details2 {
    text-align: left;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .partners-hero2 {
    justify-content: flex-end;
  }
  .partners-link1 {
    background-color: var(--dl-color-primary1-synergenex100);
  }
  .partners-link2 {
    background-color: var(--dl-color-primary-300);
  }
}
@media(max-width: 767px) {
  .partners-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .partners-details1 {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .partners-container02 {
    align-items: center;
    justify-content: flex-start;
  }
  .partners-text05 {
    text-align: center;
  }
  .partners-details-heading {
    text-align: center;
  }
  .partners-details-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .partners-details-image {
    margin-top: var(--dl-space-space-threeunits);
    margin-left: 0px;
  }
  .partners-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .partners-features-heading {
    font-family: Raleway;
    line-height: 1.6;
  }
  .partners-container04 {
    grid-template-columns: 1fr;
  }
  .partners-feature-card {
    flex-direction: row;
  }
  .partners-container05 {
    flex-direction: column;
  }
  .partners-feature-card1 {
    flex-direction: row;
  }
  .partners-container06 {
    flex-direction: column;
  }
  .partners-feature-card2 {
    flex-direction: row;
  }
  .partners-container07 {
    flex-direction: column;
  }
  .partners-feature-card3 {
    flex-direction: row;
  }
  .partners-container08 {
    flex-direction: column;
  }
  .partners-text25 {
    font-family: Raleway;
    font-weight: 600;
    line-height: 28px;
  }
  .partners-cashback1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .partners-pricing-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .partners-container10 {
    align-items: center;
    flex-direction: column;
  }
  .partners-pricing-card {
    width: 100%;
    max-width: var(--dl-size-size-maxwidth);
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .partners-pricing-card1 {
    width: 100%;
    max-width: var(--dl-size-size-maxwidth);
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .partners-pricing-card2 {
    width: 100%;
    max-width: var(--dl-size-size-maxwidth);
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .partners-pricing-fine-details1 {
    color: var(--dl-color-gray-800);
    font-size: 14px;
    text-align: left;
    line-height: 1.6;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .partners-pricing-fine-details2 {
    color: var(--dl-color-gray-800);
    font-size: 14px;
    text-align: left;
    line-height: 1.6;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .partners-hero2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .partners-link1 {
    text-align: center;
  }
  .partners-text49 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .partners-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .partners-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .partners-btn-group {
    flex-direction: column;
  }
  .partners-details-image {
    width: 100%;
    height: 100%;
  }
  .partners-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .partners-features-heading {
    text-align: center;
  }
  .partners-container05 {
    flex-direction: column;
  }
  .partners-container06 {
    flex-direction: column;
  }
  .partners-container07 {
    flex-direction: column;
  }
  .partners-container08 {
    flex-direction: column;
  }
  .partners-text27 {
    color: var(--dl-color-primary1-synergenex80);
    font-family: Raleway;
  }
  .partners-pricing-heading {
    text-align: center;
    line-height: 1.6;
  }
  .partners-pricing-sub-heading {
    color: var(--dl-color-gray-800);
  }
  .partners-text32 {
    font-family: Inter;
  }
  .partners-text36 {
    color: var(--dl-color-primary-700);
    font-family: Inter;
  }
  .partners-text40 {
    color: var(--dl-color-gray-900);
    font-family: Inter;
  }
  .partners-pricing-fine-details1 {
    color: var(--dl-color-gray-800);
  }
  .partners-pricing-fine-details2 {
    color: var(--dl-color-gray-800);
  }
  .partners-hero2 {
    align-self: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .partners-image {
    align-self: center;
  }
  .partners-container17 {
    align-items: stretch;
    justify-content: flex-end;
  }
  .partners-link1 {
    align-self: stretch;
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-primary-100);
  }
  .partners-link2 {
    align-self: stretch;
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
    background-color: var(--dl-color-primary-100);
  }
  .partners-text49 {
    font-style: normal;
    font-weight: 700;
  }
}
