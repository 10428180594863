.footer-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #272727;
}
.footer-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.footer-image {
  height: 2rem;
  object-fit: cover;
}
.footer-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-link {
  display: contents;
}
.footer-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.footer-link1 {
  display: contents;
}
.footer-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.footer-link2 {
  display: contents;
}
.footer-icon4 {
  text-decoration: none;
}
.footer-root-class-name {
  display: none;
}




@media(max-width: 767px) {
  .footer-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .footer-footer1 {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .footer-image {
    margin-bottom: var(--dl-space-space-unit);
  }
}
