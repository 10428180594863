@font-face {
  font-family: "Slider";
  font-style: normal;
  font-format: woff2;
  font-weight: 400;
  src: url("/fonts/slider_regular.woff2") ;
}
@font-face {
  font-family: "Rubik-Black";
  font-style: normal;
  font-format: truetype;
  font-weight: 900;
  src: url("/fonts/rubik-black.ttf") ;
}
:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-800: #595959;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #9a20a2ff;
  --dl-color-danger-500: #bf26b2ff;
  --dl-color-danger-700: #e147ceff;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #db242bff;
  --dl-color-primary-300: #e2454bff;
  --dl-color-primary-500: #ea8589ff;
  --dl-color-primary-700: #efc0c2ff;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-color-primary1-blue60: #efd3d5ff;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-color-secondary-grey400: #eff0f2ff;
  --dl-color-primary1-synergenex80: #b31d23ff;
  --dl-space-space-oneandhalfunits: 24px;
  --dl-color-primary1-synergenex100: #db242bff;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  font-family: "Cabin", Arial, sans-serif;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.heading2 {
  font-size: 2.5rem;
}
.heading1 {
  font-size: 4.5rem;
  font-weight: 700;
}
.sectionTitle {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1.2;
  text-transform: uppercase;
}
.heading4 {
  font-size: 1.2rem;
}
.heading3 {
  hyphens: auto;
  font-size: 1.8rem;
}
.hero-heading {
  color: var(--dl-color-danger-700);
  max-width: 800px;
  text-align: center;
  font-family: "Montserrat";
  line-height: 1.6;
  text-shadow: black 0 6px 30px;
  mix-blend-mode: hard-light;
  text-transform: uppercase;
}
.hero-subheading {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  text-align: center;
  line-height: 1.6;
  text-shadow: black 0 3px 15px, black 0 3px 10px;
}
.button-primary {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  transition: 0.3s;
  font-weight: bold;
  padding-top: 1.5rem;
  border-color: var(--dl-color-primary1-synergenex100);
  border-width: 0px;
  padding-left: 3rem;
  border-radius: 45px;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  background-color: var(--dl-color-primary1-synergenex100);
}
.button-primary:hover {
  color: var(--dl-color-gray-white);
  transform: scale(1.02);
  border-color: var(--dl-color-primary-500);
  background-color: var(--dl-color-primary-500);
}
.button-secondary {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  font-weight: bold;
  padding-top: var(--dl-space-space-unit);
  text-shadow: 0 3px 15px black;
  border-color: transparent;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: transparent;
}
.button-secondary:hover {
  border-color: var(--dl-color-gray-white);
  border-width: 0px;
}

.social-icon {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.underbutton-description {
  color: #fff4f4;
  margin-top: var(--dl-space-space-halfunit);
  mix-blend-mode: hard-light;
}
.homeStartPic {
  width: 50%;
  height: 904px;
  display: flex;
  object-fit: cover;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-radius4);
  background-size: cover;
  background-position: center;
  transition-timing-function: cubic-bezier(0, 0, 0.44, 1.18);
}
.homeStartPic:hover {
  transform: scale(1.05);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.57);
}
.product-title {
  color: #fd0a13;
  font-size: 5em;
  font-family: "Slider";
  text-shadow: 0 3px 30px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
}





.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
@media(max-width: 1600px) {
  .product-title {
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 1200px) {
  .product-title {
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 991px) {
  .hero-heading {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .heading2 {
    font-size: 1.8rem;
  }
  .heading1 {
    font-size: 2.5rem;
  }
  .heading4 {
    font-size: 1.2rem;
  }
  .heading3 {
    font-size: 1.8rem;
  }
}
@media(max-width: 479px) {
  .button-secondary {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}
