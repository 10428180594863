.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #000000;
}
.home-hero {
  width: 100%;
  display: flex;
  min-height: 95vh;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/img/sport-bg-smoke-1500h.png"),linear-gradient(135deg, rgb(42, 0, 1) 0.00%,rgb(0, 8, 15) 100.00%);
  background-position: center;
}
.home-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-navlink {
  display: contents;
}
.home-container2 {
  width: 49.5%;
  display: flex;
  min-height: 897px;
  align-items: center;
  background-size: cover;
  justify-content: center;
  text-decoration: none;
  background-image: linear-gradient(315deg, rgba(12, 35, 76, 0.77) 0.00%,rgba(24, 38, 59, 0.69) 100.00%),url("/img/synergenex-social-promo1-square-nologo-1500w.jpg");
  background-position: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.home-text {
  color: var(--dl-color-gray-white);
}
.home-navlink1 {
  display: contents;
}
.home-container3 {
  width: 49.5%;
  display: flex;
  min-height: 897px;
  align-items: center;
  background-size: cover;
  justify-content: center;
  text-decoration: none;
  background-image: linear-gradient(315deg, rgba(81, 4, 4, 0.77) 0.00%,rgba(59, 24, 24, 0.69) 100.00%),url("/img/synergenex-social-promo2-nologo-1500w.jpg");
  background-position: center;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.home-text1 {
  color: var(--dl-color-gray-white);
}
@media(max-width: 767px) {
  .home-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
